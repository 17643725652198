import Vector from "./vector";
import Line from "./line";
import Player from "./player";
import { toPixels } from "./helpers";
import { GameMode } from "./enums";
import { Time, InVector } from "./types";

class Shape {
    ctx: CanvasRenderingContext2D;
    color: string;
    points: Vector[];
    path: Path2D;
    pos: Vector; 
    angle: number;
    relativePoints: Vector[];
    rotate: boolean;
    rotationSpeed: number;

    constructor(ctx: CanvasRenderingContext2D, pos: InVector, angle: number, rotate: boolean = false, rotationSpeed: number = 0) {
        this.ctx = ctx;
        this.color = "#F955FF";
        this.points = [];
        this.path = new Path2D(); 
        this.angle = angle;
        this.relativePoints = [];
        this.rotate = rotate;
        this.rotationSpeed = rotationSpeed;

        const cw = ctx.canvas.width;
        const ch = ctx.canvas.height;

        this.pos = new Vector(toPixels(pos.x, cw), toPixels(pos.y, ch));
    }

    updateRelativePoints():void {
        
    }

    updatePoints():void {
        this.resetPoints();
        this.relativePoints = [];
        this.updateRelativePoints();
        

        for(let i = 0; i < this.relativePoints.length; i++) {
            this.addPoint(this.relativePoints[i].add(this.pos));
        }
    }

    resetPoints(): void {
        this.points = [];
    }

    addPoint(point: Vector): void {
        this.points.push(point);
    }

    updatePath():void {
        this.path = new Path2D();
        this.path.moveTo(this.points[0].x, this.points[0].y);
        for (let i = 1; i < this.points.length; i++) {
            this.path.lineTo(this.points[i].x, this.points[i].y);
        }
        this.path.closePath();
    }

    collisionPoints(player: Player): [Vector] {
        let points: [Vector] = [player.pos];
        if (player.pos === player.lastPos) return points;

        const segments = 3;
        const segmentLength = 1 / (segments + 1);
        for (let i = 1; i <= segments; i++) {
            const t = segmentLength * i;
            points.push(new Vector(
                player.pos.x * (1 - t) + player.lastPos.x * t,
                player.pos.y * (1 - t) + player.lastPos.y * t
            ))
        }

        return points;
    }

    checkCollisions(player: Player):void {
        const checkPoints = this.collisionPoints(player);
        for (let i = 0; i < checkPoints.length; i++) {
            if(this.isColliding(checkPoints[i])) {
                const collidedLine = this.collidedLine(player, this.points);
                collidedLine?.collide(player);
                if (!collidedLine) {
                    player.reverseDirection();
                }
                return;
            }
        }
        
    }

    collidedLine(player: Player, points: Vector[]): Line | null {
        for (let i = 0; i < points.length; i++) {
            const end: number = i + 1 >= points.length ? 0 : i + 1;
            const line = new Line(this.ctx, points[i], points[end]);
            if (line.isColliding(player)) return line;
            
        }
       // throw new Error("No collision line found"); // Happens when hitting corner
       return null;
    }

    isColliding(pos: Vector):boolean {
        return this.ctx.isPointInPath(this.path, pos.x, pos.y);
    }

    update(player: Player, gameMode: GameMode, time: Time) {
        if (this.rotate && this.rotationSpeed > 0) {
            this.angle += (this.rotationSpeed * time.delta) % (2 * Math.PI);
            this.updatePoints();
            this.updatePath();
        }
        if (gameMode === GameMode.Play) {
            this.checkCollisions(player);
        }
    }

    draw() {
        this.ctx.fillStyle = this.color;
        this.ctx.fill(this.path);
    }

};

export default Shape