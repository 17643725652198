import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./play.module.scss";
import Button from "../components/Button";
import Canvas from "../game/Canvas";
import useWindowSize from "../hooks/useWindowSize";
import { useParams, useNavigate, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { client } from "../config/client";
import { LevelData } from "../game/classes/levelImport";
import arrowSVG from "../assets/arrow.svg";
import soundOff from "../assets/icons/soundOff.svg"
import soundOn from "../assets/icons/soundOn.svg"
//import InteractionEventTarget from "../game/classes/interactionEvent";

const Play = (props: any) => {
  //console.log(props);

  const size = useWindowSize();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [levelComplete, setLevelComplete] = useState<boolean>(false);
  const [levelData, setLevelData] = useState<LevelData | null>(null);
  const [time, setTime] = useState<number>(0);
  const [angle, setAngle] = useState<number>(0);
  const [bounces, setBounces] = useState<number>(0);
  const [resets, setResets] = useState<number>(0);
  const [mute, setMute] = useState<boolean>(false);
  //const interactionEventTarget = new InteractionEventTarget();

  const upArrow = useRef<HTMLButtonElement>(null);
  const downArrow = useRef<HTMLButtonElement>(null);
  const launchButton = useRef<HTMLButtonElement>(null);
  const pauseButton = useRef<HTMLButtonElement>(null);
  const resetButton = useRef<HTMLButtonElement>(null);
  const angleText = useRef<HTMLInputElement>(null);

  const toggleMute = () => {
    if (mute) {
      setMute(false);
    } else {
      setMute(true);
    }
  }

  const fetchLevelData = useCallback(async () => {
    setLevelComplete(false);
    const id = urlParams.level ? urlParams.level : "level-1";

    client
      .fetch(`*[slug.current == "${id}"][0]{..., shapes, nextLevel->{slug}}`)
      .then((data) => {
        if (!data) {
          navigate("/");
        }
        setLevelData(data);
        console.log(data);
      })
      .catch(console.error);
  }, [urlParams.level, navigate]);

  useEffect(() => {
    setLevelComplete(false);
    if (
      !levelData ||
      (levelData &&
        urlParams.level &&
        urlParams.level !== levelData.slug.current)
    ) {
      fetchLevelData();
    }
  }, [levelData, fetchLevelData, urlParams.level]);

  if (!levelData) return null;

  return (
    <div className={styles.main} style={{ height: size.height }}>
      <div className={styles.top}>
        <Link to="/">GEO BASH</Link>
        <div className={styles.topButtons}>
        <button onClick={toggleMute} className={styles.muteButton}><img src={mute ? soundOn : soundOff} alt={mute ? "Sound On" : "Sound Off"}/></button>
        <Button page="/instructions" text="Instructions" small />
        <Button page="/how-it-works" text="How It Works" small />
        </div>
      </div>
      <div className={styles.side}>
        <div className={styles.controls}>
          <div className={styles.angle}>
            <div>
            <h1>Angle</h1>
            <div className={styles.angleControls}>
              <button className={styles.angleButton} ref={upArrow}>
                <img alt="up" src={arrowSVG} />
              </button>
              <button className={styles.angleButton} ref={downArrow}>
                <img alt="down" src={arrowSVG} className={styles.down} />
              </button>
              <input className={styles.angleText} ref={angleText} value={angle} readOnly type="number"/>
            </div>
            </div>
            <div>
            <button className={styles.controlsButton} ref={launchButton}>Launch</button>
            <button className={styles.controlsButton} ref={pauseButton}>Pause</button>
            <button className={styles.controlsButton} ref={resetButton}>Reset</button>
            </div>
          </div>
        </div>
        <div className={styles.scores}>
          <div className={styles.score}>
            <h1>Time</h1>
            <p>{time}</p>
          </div>
          <div className={styles.score}>
            <h1>Bounces</h1>
            <p>{bounces}</p>
          </div>
          <div className={styles.score}>
            <h1>Resets</h1>
            <p>{resets}</p>
          </div>
        </div>
      </div>
      <div className={styles.game}>
        <Canvas
          levelComplete={levelComplete}
          setLevelComplete={setLevelComplete}
          levelData={levelData}
          setTime={setTime}
          setAngle={setAngle}
          setBounces={setBounces}
          setResets={setResets}
          upArrow={upArrow}
          downArrow={downArrow}
          launchButton={launchButton}
          pauseButton={pauseButton}
          resetButton={resetButton}
          angleText={angleText}
          mute={mute}
        />
      </div>
      <AnimatePresence>
        {
         levelComplete && 
        (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className={styles.win}
          >
            <h1>Level Complete!</h1>
            
            <div className={styles.scores}>
                <div className={styles.score}>
                    <h1>Time</h1>
                    <p>{time}</p>
                </div>
                <div className={styles.score}>
                    <h1>Bounces</h1>
                    <p>{bounces}</p>
                </div>
                <div className={styles.score}>
                    <h1>Resets</h1>
                    <p>{resets}</p>
                </div>
            </div>
      

            <div className={styles.nextLinks}>
            <Button
              page={`/${levelData.slug.current}`}
              clickFunction={() => {
                setLevelComplete(false);
              }}
              text="Play Again"
              inverted
              small
            />
            <Button
              page={`/${levelData.nextLevel.slug.current}`}
              text="Next Level"
              inverted
              small
            />
            <Button
              page={`/levels`}
              text="Select Level"
              inverted
              small
            />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Play;
