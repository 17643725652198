import Shape from "./shape";
import Vector from "./vector";
import { toPixelVector } from "./helpers";
import { InVector } from "./types";

class Rect extends Shape {
    size: Vector;
    

    constructor(ctx: CanvasRenderingContext2D, pos: InVector, angle: number, size: InVector, rotate: boolean = false, rotationSpeed: number = 0) {
        super(ctx, pos, angle, rotate, rotationSpeed);

        this.size = toPixelVector(size, this.ctx);
        this.relativePoints = [];

        this.updatePoints();
        this.updatePath();
    }

    updateRelativePoints() {
        let half = this.size.divide(2);
        this.relativePoints = [];
        this.relativePoints.push(new Vector(-half.x, -half.y).rotate(this.angle))
        this.relativePoints.push(new Vector(half.x, -half.y).rotate(this.angle))
        this.relativePoints.push(new Vector(half.x, half.y).rotate(this.angle))
        this.relativePoints.push(new Vector(-half.x, half.y).rotate(this.angle))
    }

}

export default Rect;