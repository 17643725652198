// import { GameMode } from "./enums";
import Player from "./player";
import Vector from "./vector";
import { toPixelVector } from "./helpers";
import Shape from "./shape";
import { InVector } from "./types";

class Border extends Shape {
    weight: number;
    inputPoints: Vector[];
    cornerHit: boolean;

    constructor(ctx: CanvasRenderingContext2D, pos: InVector, angle: number, points: InVector[], rotate: boolean = false, rotationSpeed: number = 0) {
        super(ctx, pos, angle, rotate, rotationSpeed);

        this.weight = 5;
        this.color = "white"
        this.relativePoints = [];
        this.inputPoints = this.setupInputPoints(points);
        this.cornerHit = false;
        
        this.updatePoints();
        this.updatePath();
    }
    
    setupInputPoints(points: InVector[]):Vector[] {
        const p: Vector[] = [];
        for (let i = 0; i < points.length; i++) {
            p.push(toPixelVector(points[i], this.ctx));
        }   
        return p;
    }

    updateRelativePoints() {
        this.relativePoints = [];
        for (let i = 0; i < this.inputPoints.length; i++) {
            this.relativePoints.push(this.inputPoints[i].rotate(this.angle));
        }
    }

    isColliding(pos: Vector):boolean {
        if (this.ctx.isPointInPath(this.path, pos.x, pos.y)) {
            return false;
        } else {
            return true;
        }
    }

    checkCollisions(player: Player):void {
        if(this.isColliding(player.pos)) {
            const collidedLine = this.collidedLine(player, this.points);
            collidedLine?.collide(player);
            if (!collidedLine) {
                this.cornerHit = true;
            }
        }
    }

    draw() {
        this.ctx.strokeStyle = this.color;
        this.ctx.lineWidth = this.weight;
        this.ctx.stroke(this.path);
    }
}
export default Border