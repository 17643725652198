import Shape from "./shape";
import Vector from "./vector";
import { toPixels, } from "./helpers";
import { InVector } from "./types";

class Polygon extends Shape {
    radius: number;
    sides: number;

    constructor(ctx: CanvasRenderingContext2D, pos: InVector, angle: number, radius: number, sides: number, rotate: boolean = false, rotationSpeed: number = 0) {
        super(ctx, pos, angle, rotate, rotationSpeed);

        this.radius = toPixels(radius, this.ctx);
        this.sides = sides;
        this.relativePoints = [];

        this.updatePoints();
        this.updatePath();
    }
 
    updateRelativePoints() {
        this.relativePoints = [];
        for (let i = 0; i < this.sides; i++) {
            const polar = new Vector(this.radius, ((2*Math.PI) / this.sides) * i);
            const cart = Vector.fromPolar(polar.x, polar.y).rotate(this.angle);
            this.relativePoints.push(cart)
        }
    }

}

export default Polygon 