// To parse this data:
//
//   import { Convert, LevelData } from "./file";
//
//   const levelData = Convert.toLevelData(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface LevelData {
    _createdAt: Date;
    _id:        string;
    _rev:       string;
    _type:      string;
    _updatedAt: Date;
    border:     Border;
    goal:       Goal;
    nextLevel:  NextLevel;
    player:     Player;
    shapes?:     Shape[];
    slug:       Slug;
    title:      string;
}

export interface Border {
    angle:         number;
    points:        Pos[];
    pos:           Pos;
    rotate:        boolean;
    rotationSpeed: number;
}

export interface Pos {
    _key?: string;
    _type: Type;
    x:     number;
    y:     number;
}

export enum Type {
    Vector = "vector",
}

export interface Goal {
    pos:    Pos;
    radius: number;
}

export interface NextLevel {
    slug: Slug;
}

export interface Slug {
    _type:   string;
    current: string;
}

export interface Player {
    pos: Pos;
}

export interface Shape {
    _key:          string;
    _type:         string;
    angle:         number;
    pos:           Pos;
    rotate:        boolean;
    rotationSpeed: number;
    size?:         Pos;
    title:         string;
    radius?:       number;
    sides?:        number;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toLevelData(json: string): LevelData {
        return cast(JSON.parse(json), r("LevelData"));
    }

    public static levelDataToJson(value: LevelData): string {
        return JSON.stringify(uncast(value, r("LevelData")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

// function m(additional: any) {
//     return { props: [], additional };
// }

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "LevelData": o([
        { json: "_createdAt", js: "_createdAt", typ: Date },
        { json: "_id", js: "_id", typ: "" },
        { json: "_rev", js: "_rev", typ: "" },
        { json: "_type", js: "_type", typ: "" },
        { json: "_updatedAt", js: "_updatedAt", typ: Date },
        { json: "border", js: "border", typ: r("Border") },
        { json: "goal", js: "goal", typ: r("Goal") },
        { json: "nextLevel", js: "nextLevel", typ: r("NextLevel") },
        { json: "player", js: "player", typ: r("Player") },
        { json: "shapes", js: "shapes", typ: u(null, a(r("Shape"))) },
        { json: "slug", js: "slug", typ: r("Slug") },
        { json: "title", js: "title", typ: "" },
    ], false),
    "Border": o([
        { json: "angle", js: "angle", typ: 0 },
        { json: "points", js: "points", typ: a(r("Pos")) },
        { json: "pos", js: "pos", typ: r("Pos") },
        { json: "rotate", js: "rotate", typ: true },
        { json: "rotationSpeed", js: "rotationSpeed", typ: 0 },
    ], false),
    "Pos": o([
        { json: "_key", js: "_key", typ: u(undefined, "") },
        { json: "_type", js: "_type", typ: r("Type") },
        { json: "x", js: "x", typ: 3.14 },
        { json: "y", js: "y", typ: 3.14 },
    ], false),
    "Goal": o([
        { json: "pos", js: "pos", typ: r("Pos") },
        { json: "radius", js: "radius", typ: 3.14 },
    ], false),
    "NextLevel": o([
        { json: "slug", js: "slug", typ: r("Slug") },
    ], false),
    "Slug": o([
        { json: "_type", js: "_type", typ: "" },
        { json: "current", js: "current", typ: "" },
    ], false),
    "Player": o([
        { json: "pos", js: "pos", typ: r("Pos") },
    ], false),
    "Shape": o([
        { json: "_key", js: "_key", typ: "" },
        { json: "_type", js: "_type", typ: "" },
        { json: "angle", js: "angle", typ: 3.14 },
        { json: "pos", js: "pos", typ: r("Pos") },
        { json: "rotate", js: "rotate", typ: true },
        { json: "rotationSpeed", js: "rotationSpeed", typ: 0 },
        { json: "size", js: "size", typ: u(undefined, r("Pos")) },
        { json: "title", js: "title", typ: "" },
        { json: "radius", js: "radius", typ: u(undefined, 3.14) },
        { json: "sides", js: "sides", typ: u(undefined, 0) },
    ], false),
    "Type": [
        "vector",
    ],
};


