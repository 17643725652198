import Vector from "./vector";
import { GameMode } from "./enums";
import { Time, InVector } from "./types";
import { toPixelVector } from "./helpers";

class Player {
  ctx: CanvasRenderingContext2D;
  pos: Vector;
  lastPos: Vector;
  startPos: Vector;
  scale: number;
  normal: Vector;
  angle: number;
  speed: number;
  color: string;
  path: Path2D;
  bounces: number;
  bouncePositions: Vector[];
  hitSound: HTMLAudioElement | null;

  constructor(
    ctx: CanvasRenderingContext2D,
    pos: InVector,
    scale: number,
    hitSound: HTMLAudioElement | null
  ) {
    this.ctx = ctx;
    this.pos = toPixelVector(pos, this.ctx);
    this.lastPos = this.pos;
    this.scale = scale;
    this.normal = new Vector(1, 0);
    this.angle = 0;
    this.speed =  this.ctx.canvas.width < 400 ? 0.3 : 0.5;
    this.color = "#19F1F1";
    this.path = new Path2D();
    this.bounces = 0;
    this.bouncePositions = [this.pos]
    this.hitSound = hitSound;
    this.startPos = this.pos;

    this.path.moveTo(this.scale, 0);
    this.path.lineTo(-this.scale, -this.scale);
    this.path.lineTo(-this.scale * 0.8, 0);
    this.path.lineTo(-this.scale, this.scale);
    this.path.closePath();
  }

  update(mouse: Vector, gameMode: GameMode, time: Time, lastMouse: Vector) {
    
    switch (gameMode) {
      case GameMode.SetAngle:
        //console.log(mouse);
        if (mouse.x > 3 && mouse.y > 3 && mouse.x < this.ctx.canvas.width-3 && mouse.y < this.ctx.canvas.height-3 && lastMouse !== mouse) {
          //console.log(mouse);
          this.lookAt(mouse);
        }
        
        return
      case GameMode.Play:
        this.move(time.delta);
        return
      default:
        return;
    }

  }

  rotate(angle: number) {
    this.normal.rotate(angle);
    this.angle = this.normal.angleFromNormalized();
    console.log(this.angle);
  }

  lookAt(target: Vector) {
    if (this.pos.distance(target) < 1) return
    this.normal = this.pos.directionTo(target);
    this.angle = this.normal.angleFromNormalized();
  }


  move(deltaTime: number) {
    this.angle = this.normal.angleFromNormalized();
    this.pos = this.pos.add(this.normal.multiply(this.speed * deltaTime));
  }

  reflect(line: Vector, intersect: Vector) {
    this.normal = this.normal.reflect(line);
    this.pos = intersect;
    this.bouncePositions.push(this.pos);
    this.move(10);
    this.lastPos = this.pos;
    this.bounces += 1;
    if (this.hitSound) {
      if (this.hitSound.paused) {
        this.hitSound.play();
      } else {
        this.hitSound.pause();
        this.hitSound.currentTime = 0;
        this.hitSound.play();
      }
      
    }
  }

  reverseDirection() {
    // this.pos = this.lastPos;
    // this.move(-10);
    // this.normal = this.normal.rotate(0.1 * Math.PI);
    
    // this.lastPos = this.pos;
    if (this.bouncePositions.length > 2) {
      this.pos = this.bouncePositions[this.bouncePositions.length - 1];
      this.normal = this.normal.rotate(Math.PI);
    } else {
      this.pos = this.startPos;
    }
    
    this.move(30);
    this.normal = this.normal.rotate(0.1 * Math.PI);
    this.lastPos = this.pos;
  }

  draw() {
    const line = new Path2D()
    line.moveTo(this.bouncePositions[0].x, this.bouncePositions[0].y);
    if (this.bouncePositions.length > 1) {
      for (let i = 1; i < this.bouncePositions.length; i++) {
        line.lineTo(this.bouncePositions[i].x, this.bouncePositions[i].y);
      }
    }
    line.lineTo(this.pos.x, this.pos.y);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = this.color;
    this.ctx.setLineDash([3, 10]);
    this.ctx.stroke(line);
    this.ctx.setLineDash([]);

    this.ctx.fillStyle = this.color;
    this.ctx.translate(this.pos.x, this.pos.y);
    this.ctx.rotate(this.angle);
    this.ctx.fill(this.path);
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }
}

export default Player;
