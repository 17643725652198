export type InteractionEvent = { down: boolean};

interface IInteractionEventTarget {
    addListener(callback: (evt: CustomEvent<InteractionEvent>) => void): void
    dispatch(event: InteractionEvent): boolean;
    removeListener(callback: (evt: CustomEvent<InteractionEvent>) => void): void
}

class InteractionEventTarget extends EventTarget implements IInteractionEventTarget {
    private readonly targetType = 'interaction-event';

    addListener(callback: (evt: CustomEvent<InteractionEvent>) => void): void {
    return this.addEventListener(this.targetType, callback as (evt: Event) => void);
    }

    dispatch(event: InteractionEvent): boolean {
    return this.dispatchEvent(new CustomEvent(this.targetType, { detail: event }));
    }

    removeListener(callback: (evt: CustomEvent<InteractionEvent>) => void): void {
    return this.removeEventListener(this.targetType, callback as (evt: Event) => void);
    }
}

export default InteractionEventTarget