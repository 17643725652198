import React from "react";
import Button from "../components/Button";
import styles from "./welcome.module.scss"
import square from "../assets/square-svg.svg"
import player from "../assets/player-svg.svg"

const Welcome = (props) => {

    return (
        <main className={styles.main}>
            <div className={styles.heading}>
                <img src={square} alt=""/>
                <h1>GEO BASH</h1>
                <img src={player} alt=""/>
            </div>
            <div className={styles.buttons}>
                <Button page={"/level-01"} text="Start Game" />
                <Button page={"/instructions"} text="Instructions" />
                <Button page={"/levels"} text="Select Level" />
                <Button page={"/how-it-works"} text="How It Works" />
                <Button page={"https://mathscity.co.uk/"} text="Visit Maths City" />
            </div>
        </main>
    )
}

export default Welcome;