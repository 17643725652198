import { GameMode } from "./enums";
import Player from "./player";
import Vector from "./vector";

class Line {
    cxt: CanvasRenderingContext2D;
    p1: Vector;
    p2: Vector;
    path: Path2D;
    normal: Vector;
    angle: number;

    constructor(ctx: CanvasRenderingContext2D, p1: Vector, p2: Vector) {
        this.cxt = ctx;
        this.p1 = p1; 
        this.p2 = p2; 
        this.path = new Path2D(); 

        this.normal = this.p1.directionTo(this.p2).rotate(Math.PI/2);
        this.angle = this.normal.angleFromNormalized();
        
        this.path.moveTo(this.p1.x, this.p1.y);
        this.path.lineTo(this.p2.x, this.p2.y);
    }

    update(gameMode: GameMode, player: Player) {
        if (gameMode === GameMode.Play) {
            this.checkForCollision(player,);
        }
    }

    checkForCollision(player: Player) {
        if (this.isColliding(player)) {
            this.collide(player);
        }
    }

    isColliding(player: Player):boolean {
        const intersecting = Vector.doLinesIntersect(
            player.lastPos,
            player.pos,
            this.p1,
            this.p2
            );
        return intersecting;
    }

    collide(player: Player):void {
        const intersectionPoint = Vector.getLineIntersection(player.lastPos, player.pos, this.p1, this.p2);
        player.reflect(this.normal, intersectionPoint);
    }

    draw() {
        this.cxt.lineWidth = 5;
        this.cxt.strokeStyle = "red";
        this.cxt.stroke(this.path);
    }
}

export default Line;