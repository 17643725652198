import styles from "./instructions.module.scss"
import { Link } from "react-router-dom"
import Button from "../components/Button"

const HowItWorks = (props: any) => {

    return (
        <div className={styles.main}>
            <div className={styles.top}>
                <Link to="/">GEO BASH</Link>
                <Button page="/level-01" text="Play" small />
            </div>
            <div className={styles.content}>
                <h1>How It Works</h1>
                <br/><br/>
                
                <p>In this game, a player helps the spaceship reach its target by bouncing off walls and obstacles. Let's learn how the underlying maths that makes games like this works.</p>
                <div className={styles.images}>
                    <img src="/how_it_works/gb1.png" alt="" />
                </div>

                <h2>Position</h2>
                <p>Every object in the game has a position. Positions are written using coordinates.</p>
                <p>The first number gives the horizontal distance from the left of the screen, and the second number is
the vertical distance from the bottom of the screen. These distances are measured in pixels.</p>
                <div className={styles.images}>
                <img src="/how_it_works/gb2.png" alt="" />
                </div>
                <p>Without coordinates, everything would be jumbled up!</p>

                <h2>Direction</h2>
                <p>By moving the pointer, you can change the direction of the spaceship.</p>
                <div className={styles.images}>
                <img src="/how_it_works/gb3.png" alt="" />
                </div>
                <p>The horizontal and vertical distances, from the spaceship to the pointer, is called a vector. Using the
vector, we can work out the direction of the spaceship.</p>

                <h2>Distance</h2>
                <p>The spaceship's vector also gives us the distance to the pointer.</p>
                <p>To calculate the distance, we use the famous Pythagorean Theorem.</p>
                <div className={styles.images}>
                <img src="/how_it_works/gb5.png" alt="" />
                </div>
                
                <h2>Speed</h2>
                <p>By dividing the vector by its length, we create a vector of length one.</p>
                <div className={styles.images}>
                <img src="/how_it_works/gb6.png" alt="" />
                </div>
                <p>If we add this vector to the spaceship's position, the spaceship will appear to have moved a tiny
amount.</p>
                <p>In the game, this happens sixty times a second! This is so fast that the spaceship appears to be
moving smoothly.</p>

                <h2>Collisions!</h2>
                <p>The spaceship hits an obstacle when its position is within the outline of the shape.</p>
                
                <div className={styles.images}>
                <img src="/how_it_works/gb8.png" alt="" />
                </div>
                <p>If it is, the game triggers a collision, causing the spaceship to bounce off the object.</p>

                <h2>Vector Addition</h2>
                <p>We can add two vectors together by placing the two vectors end-to-end.</p>
                
                <div className={styles.images}>
                <img src="/how_it_works/gb9.png" alt="" />
                </div>
                <p>We can now make a third vector that travels, in a straight line, from the beginning of the first vector
to the end of the second vector. The new vector is called the sum of the original two vectors.</p>

                <h2>Reflection</h2>
                <p>When the spaceship hits an obstacle, it bounces off and travels in a new direction.</p>
                <p>We can work out the spaceship's new direction using the maths of vector addition.</p>
                <div className={styles.images}>
                <img src="/how_it_works/gb10.png" alt="" />
                </div>
                <p>In this diagram, V is the original vector, R is the reflected vector, and N is a vector at right-angles to
the surface.</p>
<p>Using vector addition, we have: R = N + P</p>
<p>We can replace P with the sum: P = V + N</p>
<p>This results in the following equation for the reflected vector: R = V + 2N.</p>
<p>This is how the game calculates the new direction of travel after bouncing off an object.</p>


            </div>
        </div>
    )
}

export default HowItWorks