class Vector {
  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  // Add another vector to this vector
  public add(v: Vector): Vector {
    return new Vector(this.x + v.x, this.y + v.y);
  }

  // Subtract another vector from this vector
  public subtract(v: Vector): Vector {
    return new Vector(this.x - v.x, this.y - v.y);
  }

  // Multiply this vector by a scalar
  public multiply(scalar: number): Vector {
    return new Vector(this.x * scalar, this.y * scalar); 
  }

  // Divide this vector by a scalar
  public divide(scalar: number): Vector {
    if (scalar === 0) {
      throw new Error("Cannot divide by zero.");
    }
    return new Vector(this.x / scalar, this.y / scalar);
  }

  // Calculate the magnitude of this vector
  public magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  // Normalize this vector
  public normalize(): Vector {
    const mag = this.magnitude();
    if (mag === 0) {
      throw new Error("Cannot normalize a zero-length vector.");
    }
    return new Vector(this.x / mag, this.y / mag);
  }

  // Calculate the dot product of this vector and another vector
  public dot(v: Vector): number {
    return this.x * v.x + this.y * v.y;
  }

  // Calculate the distance between this vector and another vector
  public distance(v: Vector): number {
    const dx = this.x - v.x;
    const dy = this.y - v.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  // Rotate this vector by an angle (in radians)
  public rotate(angle: number): Vector {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const x = this.x * cos - this.y * sin;
    const y = this.x * sin + this.y * cos;
    return new Vector(x, y);
  }

  // Create a string representation of this vector
  public toString(): string {
    return `(${this.x.toFixed(2)}, ${this.y.toFixed(2)})`;
  }

  // Creates an angle in radians from the vector
  public angleFromNormalized(): number {
    return Math.atan2(this.y, this.x);
  }

  // Returns a normalized vector from an anfle in radians
  public static fromAngle(angle: number): Vector {
    const x = Math.cos(angle);
    const y = Math.sin(angle);
    return new Vector(x, y);
  }

  // Returns the angle to look at another vector
  public angleToLookAt(target: Vector): number {
    const dx = target.x - this.x;
    const dy = target.y - this.y;
    return Math.atan2(dy, dx);
  }

  // Returns the normaled vector in the direction of input
  public directionTo(target: Vector): Vector {
    const dx = target.x - this.x;
    const dy = target.y - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance === 0) {
      throw new Error(`Cannot calculate direction for two identical vectors. 1 (${this.x},${this.y}), 2(${target.x},${target.y})`);
    }

    return new Vector(dx / distance, dy / distance);
  }

  //Reflects the normalised vector along another normal


  public reflect(normal: Vector): Vector {
    // Ensure the normal vector is normalized
    if (Math.abs(normal.magnitude() - 1) > 1e-6) {
      throw new Error("The input normal vector must be normalized.");
    }

    // Calculate the dot product between the current vector and the normal vector
    const dotProduct = this.dot(normal);

    // Compute the reflection using the formula: R = V - 2 * (V dot N) * N
    const reflectedX = this.x - 2 * dotProduct * normal.x;
    const reflectedY = this.y - 2 * dotProduct * normal.y;

    // console.log(new Vector(this.x, this.y));
    // console.log(new Vector(reflectedX, reflectedY));
    
    return new Vector(reflectedX, reflectedY);
  }

  public static doLinesIntersect(a1: Vector, a2: Vector, b1: Vector, b2: Vector): boolean {
    // Calculate the direction vectors
    const da = a2.subtract(a1);
    const db = b2.subtract(b1);
  
    // Calculate the determinant
    const determinant = da.x * db.y - da.y * db.x;
  
    // Check if lines are parallel (determinant is close to zero)
    if (Math.abs(determinant) < 1e-6) {
      return false;
    }
  
    // Calculate the normalized barycentric coordinates
    const c = b1.subtract(a1);
    const t = (c.x * db.y - c.y * db.x) / determinant;
    const u = (c.x * da.y - c.y * da.x) / determinant;
  
    // Check if the line segments intersect (both t and u are between 0 and 1)
    return t >= 0 && t <= 1 && u >= 0 && u <= 1;
  }

  public static getLineIntersection(a1: Vector, a2: Vector, b1: Vector, b2: Vector): Vector {
    const dxA = a2.x - a1.x;
    const dyA = a2.y - a1.y;
    const dxB = b2.x - b1.x;
    const dyB = b2.y - b1.y;
  
    const xDiff = a1.x - b1.x;
    const yDiff = a1.y - b1.y;
  
    const div = dxB * dyA - dyB * dxA;
  
    const u = (xDiff * dyA - yDiff * dxA) / div;
  
    const x = a1.x + u * dxA;
    const y = a1.y + u * dyA;
  
    return new Vector(x, y);
  }

  public static fromPolar(magnitude: number, angle: number): Vector {
    const x = magnitude * Math.cos(angle);
    const y = magnitude * Math.sin(angle);
  
    return new Vector(x, y);
  }
}

export default Vector;
