import styles from "./button.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Button = (props) => {
    const { page, text, small, inverted, clickFunction, external } = props;


    if (external) {
        return(
            <a 
                href={page}
                onClick={clickFunction}
                className={classNames(
                    styles.main,
                    {
                        [styles.sm]: small,
                        [styles.inverted]: inverted
                    })} 
            >
                {text}
            </a>
        )
    }
    return(
        <Link 
            to={page}
            onClick={clickFunction}
            className={classNames(
                styles.main,
                {
                    [styles.sm]: small,
                    [styles.inverted]: inverted
                })} 
        >
            {text}
        </Link>
    )
}

export default Button