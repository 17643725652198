import Vector from "./classes/vector";
import InteractionEventTarget from "./classes/interactionEvent";
import Player from "./classes/player";
import Polygon from "./classes/polygon";
import Rect from "./classes/rect";
import Border from "./classes/border";
import Goal from "./classes/goal";
import { GameMode, Shape } from "./classes/enums";
import { InteractionEvent } from "./classes/interactionEvent";
import { Time } from "./classes/types";
import { Convert, LevelData } from "./classes/levelImport";
// import { ChangeEvent } from "react";


class Game {
    ctx: CanvasRenderingContext2D;
    gameMode: GameMode;
    player: Player;
    border: Border;
    goal: Goal;
    generated: boolean = false; 
    shapes: (Rect | Polygon)[];
    winFunction: Function;
    interactionEventTarget: InteractionEventTarget;
    elapsedTime: number;
    resets: number;
    flySound: HTMLAudioElement | null;
    hitSound: HTMLAudioElement | null;
    launchSound: HTMLAudioElement | null;
    stopSound: HTMLAudioElement | null;
    winSound: HTMLAudioElement | null;
    lastMouse: Vector;

    constructor(
        ctx: CanvasRenderingContext2D, 
        level: JSON, winFunction: Function, 
        interactionEventTarget: InteractionEventTarget, 
        upArrow: HTMLButtonElement, 
        downArrow: HTMLButtonElement,
        launchButton: HTMLButtonElement,
        pauseButton: HTMLButtonElement,
        angleText: HTMLInputElement,
        flySound: HTMLAudioElement | null,
        hitSound: HTMLAudioElement | null,
        launchSound: HTMLAudioElement | null,
        stopSound: HTMLAudioElement | null,
        winSound: HTMLAudioElement | null,
        ) {
        this.ctx = ctx;
        this.gameMode = GameMode.SetAngle;
        this.generated = false;
        this.shapes = [];
        this.winFunction = winFunction;
        this.interactionEventTarget = interactionEventTarget;
        this.elapsedTime = 0;
        this.resets = 0;
        this.flySound = flySound;
        this.hitSound = hitSound;
        this.launchSound = launchSound;
        this.stopSound = stopSound;
        this.winSound = winSound;
        this.lastMouse = new Vector(0,0);

        interactionEventTarget.addListener(this.mouseInteraction);

        const levelData: LevelData = Convert.toLevelData(JSON.stringify(level));
        this.player = new Player(ctx, levelData.player.pos, this.ctx.canvas.width < 400 ? 10 : 20, hitSound);
        this.border = new Border(ctx, levelData.border.pos, levelData.border.angle, levelData.border.points, levelData.border.rotate, levelData.border.rotationSpeed);
        if (levelData.shapes && levelData.shapes.length >= 1) {
            levelData.shapes.forEach(shape => {
                switch(shape._type) {
                    case Shape.Rect:
                        if (!shape.size) return null
                        this.shapes.push(new Rect(ctx, shape.pos, shape.angle, shape.size, shape.rotate, shape.rotationSpeed))
                        return null;
                    case Shape.Poly:
                        if (!shape.radius || !shape.sides) return null
                        this.shapes.push(new Polygon(ctx, shape.pos, shape.angle, shape.radius, shape.sides, shape.rotate, shape.rotationSpeed));
                        return null
                    default:
                        return null
                }
            }); 
        }
        this.goal = new Goal(ctx, levelData.goal.pos, levelData.goal.radius, this.winSound);
        
        upArrow.addEventListener("click", () => {
            if (this.gameMode === GameMode.SetAngle) {
            this.rotatePlayer(5 * (Math.PI / 180));
            }
        }, false)

        downArrow.addEventListener("click", () => {
            if (this.gameMode === GameMode.SetAngle) {
            this.rotatePlayer(-(5 * (Math.PI / 180)));
            }
        }, false)

        launchButton.addEventListener("click", () => {
            if (this.gameMode === GameMode.SetAngle) {
                this.gameMode = GameMode.Play;
            } 
        }, false)

        pauseButton.addEventListener("click", () => {
            if (this.gameMode === GameMode.Play) {
                this.gameMode = GameMode.SetAngle;
                this.player.bouncePositions.push(this.player.pos);
                this.resets += 1;
            }
        }, false)

        angleText.addEventListener("change", (event ) => {
            console.log(event);
            
            if (this.gameMode === GameMode.SetAngle) {
               //const angle = event.target.value; 
            //    if (angle) {

            //    }
                
            }
        }, false)
        // this.generateLevel(ctx, level);
    }

    rotatePlayer = (angle: number) => {
        this.player.normal = this.player.normal.rotate(angle);
        this.player.angle = this.player.normal.angleFromNormalized();
    }


    mouseInteraction = (interactionEvent: CustomEvent<InteractionEvent>):void => {
        interactionEvent.stopPropagation()
        
            if (interactionEvent.detail.down) {
              //  console.log(interactionEvent.detail);
                
                this.mouseDown();
            } else {
                this.mouseUp();  
            }
        
        
    }
    
    mouseUp = () => {
        if (this.gameMode === GameMode.SetAngle) {
            this.gameMode = GameMode.Play;
            if (this.flySound) {
                this.flySound.play();
            }
            if (this.launchSound) {
                this.launchSound.play();
            }
            if (this.stopSound) {
                this.stopSound.pause();
                this.stopSound.currentTime = 0;
            }
        } 
        else if (this.gameMode === GameMode.Play) {
            this.gameMode = GameMode.SetAngle;
            this.player.bouncePositions.push(this.player.pos);
            this.resets += 1;
            if (this.flySound) {
                this.flySound.pause();
            }
            if (this.launchSound) {
                this.launchSound.pause();
                this.launchSound.currentTime = 0;
            }
            if (this.stopSound) {
                this.stopSound.play();
            }
        }
    }
    
    mouseDown = () => {
        
    }
    
    draw = (time: Time, mouse: Vector) => {
        //console.log(this.gameMode);
        
        this.player.update(mouse, this.gameMode, time, this.lastMouse);
        this.border.update(this.player, this.gameMode, time);
        this.shapes.forEach(shape => {
            shape.update(this.player, this.gameMode, time);
        });
        this.goal.update(this.player, this.gameMode, time);

        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.border.draw();
        if (this.shapes.length >= 1) {
            this.shapes.forEach(shape => {
                shape.draw();
            });
        }
        this.goal.draw();
        this.player.draw();

        this.lastMouse = mouse;
        this.player.lastPos = this.player.pos;

        if (this.border.cornerHit) {
            this.gameMode = GameMode.SetAngle;
            this.border.cornerHit = false;
            if (this.player.bouncePositions.length > 1) {
                this.player.pos = this.player.bouncePositions[this.player.bouncePositions.length - 2]
                this.player.bouncePositions.pop();
            } else {
                this.player.pos = this.player.startPos;
                this.player.bouncePositions = [this.player.startPos]
            }
        }

        if (this.gameMode === GameMode.Play) {
            this.elapsedTime += time.delta;
        }
        
        if (this.goal.win) {
            if (this.flySound) {
                this.flySound.pause();
            }
            this.gameMode = GameMode.Win;
            this.winFunction(this.goal.winTime);
        }
        
    }
    
    
}




export {Game}