enum GameMode {
    SetAngle = "SET ANGLE",
    Play = "PLAY",
    Win = "WIN"
};

enum Shape {
    Rect = "rectangle",
    Poly = "polygon"
}

export {GameMode, Shape}