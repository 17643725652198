import { InVector } from "./types";
import Vector from "./vector";

function toPixels(alue: number, dimension: number): number;
function toPixels(alue: number, ctx: CanvasRenderingContext2D): number;

function toPixels (value: number, scale: CanvasRenderingContext2D | number): number {
    let s = scale;
    if (typeof s !== "number") {
        s = s.canvas.width;
    }
    return value * s;
}

function toPixelVector (inVector: InVector, ctx: CanvasRenderingContext2D): Vector {
    const w = ctx.canvas.width;
    const h  = ctx.canvas.height;
    return new Vector(inVector.x * w, inVector.y * h);
}

export {toPixels, toPixelVector}