import styles from "./instructions.module.scss"
import { Link } from "react-router-dom"
import Button from "../components/Button"

const Instructions = (props: any) => {

    return (
        <div className={styles.main}>
            <div className={styles.top}>
                <Link to="/">GEO BASH</Link>
                <Button page="/level-01" text="Play" small />
            </div>
            <div className={styles.content}>
                <h1>Instructions</h1>
                <br/><br/>
                <h2>Goal</h2>
                <p>The aim of the game is to reach the green goal! Bounce around the walls and finish the level with the quickest time, the least bounces, or the least resets!</p>
                <div className={styles.images}>
                    <img src="/gif/whattodo1.png" alt="" />
                    <img src="/gif/whattodo2.png" alt="" />
                </div>

                <h2>Set The Angle</h2>
                <p>Use the buttons on the side or your mouse to set the angle of the ship</p>
                <div className={styles.images}>
                    <img src="/gif/angleButtons.gif" alt="" />
                    <img src="/gif/angleMouse.gif" alt="" />
                </div>

                <h2>Launch!</h2>
                <p>Press the Launch button or click your mouse on the game to launch!</p>
                <div className={styles.images}>
                    <img src="/gif/luanchButton.gif" alt="" />
                    <img src="/gif/launchMouse.gif" alt="" />
                </div>

                <h2>Pause</h2>
                <p>If you get stuck you can press the pause button to stop the ship and change the angle.
                    Click again or press the launch button to restart. This will increase the amount of Resets counted so try not to use this unless it's an emergency. 
                </p>
                <div className={styles.images}>
                    <img src="/gif/pauseButton.gif" alt="" />
                    <img src="/gif/pauseRelaunch.gif" alt="" />
                </div>

                <h2>Reset</h2>
                <p>Press the reset button to go back to the start and reset all the trackers. Use this if you aren't happy with how long or how many bounces and resets are used.
                </p>
                <div className={styles.images}>
                    <img src="/gif/resetButton.gif" alt="" />
                </div>

                <h2>Win!</h2>
                <p>When you complete a level you can move onto the next one, or play again to get a better score! 
                </p>
                <div className={styles.images}>
                    <img src="/gif/complete.gif" alt="" />
                </div>

                <h2>How Does it Work?</h2>
                <p>Want to learn how we made it? Find out <Link to="/how-it-works">here</Link>! 
                </p>

            </div>
        </div>
    )
}

export default Instructions