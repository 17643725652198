import "./styles/globals.scss";
import {Fragment} from "react"
import { Welcome, Play, LevelSelect } from "./pages";
import { Routes, Route, useLocation } from "react-router-dom";
import Instructions from "./pages/Instuctions";
import HowItWorks from "./pages/HowItWorks";

function App() {
  const params = useLocation();

  return (
    <Fragment>
      <Routes location={params.pathname}>
      <Route path="levels" key={params.pathname}  element={
          <LevelSelect  />
          } />
           <Route path="instructions" key={params.pathname}  element={
          <Instructions  />
          } />
          <Route path="how-it-works" key={params.pathname}  element={
          <HowItWorks  />
          } />
        <Route path=":level" key={params.pathname}  element={
          <Play  />
          } />
        <Route index element={
          <Welcome />
          } />
       
      </Routes>
    </Fragment>
  );
}

export default App;