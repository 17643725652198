import Vector from "./vector";
import Player from "./player";
import { Time, InVector } from "./types";
import { GameMode } from "./enums";
import { toPixelVector, toPixels } from "./helpers";

class Goal {
    ctx: CanvasRenderingContext2D;
    path: Path2D;
    pos: Vector;
    radius: number;
    color: string;
    winTime: number;
    win: boolean;
    winSound: HTMLAudioElement | null;

    constructor(ctx: CanvasRenderingContext2D, pos: InVector, radius: number, winSound: HTMLAudioElement | null) {
        this.ctx = ctx;
        this.path = new Path2D();
        this.pos = toPixelVector(pos, this.ctx);
        this.radius = toPixels(radius, this.ctx);
        this.color = "#08FF08";
        this.winTime = 0;
        this.win = false;
        this.winSound = winSound;
        
        this.updatePath();
    }

    updatePath() {
        this.path.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI);
    }

    update(player: Player, gameMode: GameMode, time: Time) {
        this.checkForCollisions(player, time.elapsed);
    }

    checkForCollisions(player: Player, elapsed: number) {
        if (this.isColliding(player)) {
            this.collide(elapsed);
        }
    }

    collide(elapsed: number) {
        //console.log("WIN");
        this.winTime = elapsed;
        this.win = true;
        if (this.winSound) {
            this.winSound.play();
        }
        
    }

    isColliding(player: Player):boolean {
        return this.ctx.isPointInPath(this.path, player.pos.x, player.pos.y);
    }

    draw() {
        this.ctx.fillStyle = this.color;
        this.ctx.fill(this.path)
    }

}

export default Goal;