import { createClient } from "@sanity/client";

export const client = createClient({
    projectId: 'iu9e88qw',
    dataset: 'production',
    useCdn: true, // set to `true` to fetch from edge cache
    apiVersion: '2023-03-30', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
  })
  
  export async function getlevel(id: string) {
    const level = await client.fetch(`*[slug.current == id][0]`)
    return level
  }


  