import { useRef, useEffect, useCallback, Fragment } from "react";
import styles from "./game.module.scss";
import { Game } from "./game";
import Vector from "./classes/vector";
import InteractionEventTarget from "./classes/interactionEvent";
import useWindowSize from "../hooks/useWindowSize";
import { flySound, hitSound, launchSound, stopSound, winSound } from "../assets/sound";

const Canvas = (props: any) => {
  const {
    levelComplete,
    setLevelComplete,
    levelData,
    setTime,
    setAngle,
    setBounces,
    setResets,
    upArrow,
    downArrow,
    launchButton,
    pauseButton,
    resetButton,
    angleText,
    mute
  } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const flySoundRef = useRef<HTMLAudioElement>(null);
  const hitSoundRef = useRef<HTMLAudioElement>(null);
  const launchSoundRef = useRef<HTMLAudioElement>(null);
  const stopSoundRef = useRef<HTMLAudioElement>(null);
  const winSoundRef = useRef<HTMLAudioElement>(null);

  // const [levelLoaded, setLevelLoaded] = useState<boolean>(false);
  const size = useWindowSize();

  const winGame = useCallback(
    (elapsed: number) => {
      if (!levelComplete) {
        setLevelComplete(true);
        //console.log(elapsed);
      }
    },
    [levelComplete, setLevelComplete]
  );

  useEffect(() => {
    if (!canvasRef.current) throw Error("canvasRef is not assigned");
    const canvas: HTMLCanvasElement = canvasRef.current;
    const context: CanvasRenderingContext2D = canvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;
    let lastTime = Date.now();
    let mouse = new Vector(0, 0);
    let lastMouse = new Vector(0, 0);
    const interactionEventTarget = new InteractionEventTarget();

    canvas.addEventListener(
      "mousedown",
      function (e: MouseEvent) {
        mouse = getMouse(canvas, e);
        interactionEventTarget.dispatch({ down: true });
      },
      false
    );
    canvas.addEventListener(
      "mouseup",
      function (e: MouseEvent) {
        mouse = getMouse(canvas, e);
        interactionEventTarget.dispatch({ down: false });
      },
      false
    );
    canvas.addEventListener(
      "mousemove",
      function (e: MouseEvent) {
        mouse = getMouse(canvas, e);
      },
      false
    );

    // Set up touch events for mobile, etc
    canvas.addEventListener(
      "touchstart",
      function (e: TouchEvent) {
        //mouse = getTouchPos(canvas, e);
        var touch = e.touches[0];

        var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvas.dispatchEvent(mouseEvent);
      },
      false
    );
    canvas.addEventListener("touchend", (event) => {
      event.preventDefault();
      const touch = event.changedTouches[0];
      if (touch) {
        const mouseEvent = new MouseEvent("mouseup", {
          clientX: touch.clientX,
          clientY: touch.clientY,
          button: 0, // simulate left button
        });
        canvas.dispatchEvent(mouseEvent);
      }
    });
    canvas.addEventListener(
      "touchmove",
      function (e: TouchEvent) {
        var touch = e.touches[0];
        if (touch) {
          var mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          canvas.dispatchEvent(mouseEvent);
        }
      },
      false
    );

    // Prevent scrolling when touching the canvas
    document.body.addEventListener(
      "touchstart",
      function (e: TouchEvent) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchend",
      function (e: TouchEvent) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchmove",
      function (e: TouchEvent) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );

    // Get the position of the mouse relative to the canvas
    function getMouse(
      canvasDom: HTMLCanvasElement,
      mouseEvent: MouseEvent
    ): Vector {
      var rect = canvasDom.getBoundingClientRect();
      if (mouseEvent.clientX <= 0 || mouseEvent.clientY <= 0) return lastMouse;
      const mouse = new Vector(
        mouseEvent.clientX - rect.left,
        mouseEvent.clientY - rect.top
      );
      lastMouse = mouse;
      return mouse;
    }

    function resizeCanvas(
      ctx: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement
    ) {
      const width = ctx.canvas.clientWidth;
      const height = ctx.canvas.clientHeight;

      if (ctx.canvas.width !== width || ctx.canvas.height !== height) {
        const ratio = Math.min(2, window.devicePixelRatio);
        ctx.canvas.height = ctx.canvas.clientHeight;
        ctx.canvas.width = ctx.canvas.clientWidth;
        ctx.scale(ratio, ratio);
        return true;
      }

      return false;
    }

    const time = {
      elapsed: 0,
      delta: 0,
    };

    let animationFrameId: DOMHighResTimeStamp;

    resizeCanvas(context, canvas);

    let game = new Game(
      context, 
      levelData, 
      winGame, 
      interactionEventTarget, 
      upArrow.current, 
      downArrow.current, 
      launchButton.current, 
      pauseButton.current, 
      angleText.current,
      flySoundRef.current,
      hitSoundRef.current,
      launchSoundRef.current,
      stopSoundRef.current,
      winSoundRef.current,
      );

    const resetStates = () => {
      if (!levelComplete) {
        setAngle(0);
        setTime(0);
        setBounces(0);
        setResets(0);
      }
    };

    const updateStates = () => {
      if (game.player.angle) {
        const a = Math.floor(game.player.angle * (180 / Math.PI));
        setAngle(a < 0 ? 360 - Math.abs(a) : a);
      }
      if (game.elapsedTime) {
        const t = (game.elapsedTime / 1000).toFixed(2);
        setTime(t);
      }
      if (game.player.bounces) {
        setBounces(game.player.bounces);
      }
      if (game.resets) {
        setResets(game.resets);
      }
    };
    const render = () => {
      //resizeCanvas(context, canvas);

      const timeNow = Date.now();
      time.delta = timeNow - lastTime;
      lastTime = timeNow;
      time.elapsed += time.delta;
      game.draw(time, mouse);
      updateStates();
      animationFrameId = window.requestAnimationFrame(render);
    };

    // setup(context, interactionEventTarget, levelData);
    resetStates();
    render();

    resetButton.current.addEventListener("click", () => {
      game = new Game(
        context, 
        levelData, 
        winGame, 
        interactionEventTarget, 
        upArrow.current, 
        downArrow.current, 
        launchButton.current, 
        pauseButton.current, 
        angleText.current,
        flySoundRef.current,
        hitSoundRef.current,
        launchSoundRef.current,
        stopSoundRef.current,
        winSoundRef.current,
        );
      resetStates();
      render();
    })

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [
    winGame,
    levelData,
    setAngle,
    setTime,
    setResets,
    setBounces,
    levelComplete,
    upArrow,
    downArrow,
    launchButton,
    pauseButton,
    resetButton,
    angleText
  ]);

  return (
    <Fragment>
    <canvas
      className={styles.main}
      width={size.width < 834 ? size.width : size.height - 55}
      height={size.width < 834 ? size.width : size.height - 55}
      ref={canvasRef}
    ></canvas>
      <audio ref={flySoundRef} loop src={flySound} muted={mute}></audio>
      <audio ref={hitSoundRef} src={hitSound} muted={mute}></audio>
      <audio ref={launchSoundRef} src={launchSound} muted={mute}></audio>
      <audio ref={stopSoundRef} src={stopSound} muted={mute}></audio>
      <audio ref={winSoundRef} src={winSound} muted={mute}></audio>
    </Fragment>
  );
};

export default Canvas;
