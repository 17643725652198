import styles from "./levelselect.module.scss"
import Button from "../components/Button"
import { client } from "../config/client";
import { useCallback, useEffect, useState } from "react";
import { Convert, LevelsData } from "./levelsImport";
import { Link } from "react-router-dom";

const LevelSelect = () => {

    const [levelsData, setLevelsData] = useState<LevelsData[]>();

    const fetchLevelsData = useCallback(async () => {


        client
        .fetch(`*[_type == "level"] | order(title asc){title, slug}`)
        .then((data) => {
            setLevelsData(Convert.toLevelsData(JSON.stringify(data)));
            console.log(data);
        })
        .catch(console.error);
    },[])

    useEffect(() => {
        if (!levelsData) {
            fetchLevelsData();
        }
    },[levelsData, fetchLevelsData])

    if (!levelsData) return null;

    return(
        <div className={styles.main}>
            <div className={styles.top}>
            <Link to="/">GEO BASH</Link>
                <Button page="/how" text="How It Works" small />
            </div>
            <div className={styles.levels}>
                {
                    levelsData.map((level, index) => (
                        <Button page={`/${level.slug.current}`} text={level.title} key={index} />
                    ))
                }
            </div>
        </div>
    )
}

export default LevelSelect